@font-face {
  font-family: "Roboto";
  src: local("Roboto"), url("./assets/fonts/Roboto/Roboto-Regular.ttf");
}
@font-face {
  font-family: "Dancing";
  src: url("./assets/fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf");
}

.myTable {
  width: 100%;
  border: 1px solid #82ccfa;
  border-collapse: collapse;
}
#tdh {
  height: 200px;
  border: 1px solid #82ccfa;
}

#thead {
  background-color: #434991;
  color: white;
}

.centered {
  padding-left: 65px;
}

.td {
  width: 198px;
  border: 1px solid #82ccfa;
}

.tdh2 {
  height: 60px;
  border: 1px solid #82ccfa;
}
.servhead {
  width: 184px;
  border: 1px solid #82ccfa;
}
#tdspan {
  height: 30px;
}
.thead2 {
  border: 1px solid #82ccfa;
}

.mytab {
  width: 100%;
  border: 1px solid #82ccfa;
  border-collapse: collapse;
}
.h1 {
  width: 198px;
  border: 1px solid #82ccfa;
}

.tab {
  width: 100%;
  border: 1px solid #82ccfa;
  border-collapse: collapse;
}
